import '../scss/style.scss';


window.addEventListener('load',()=> {
  const loading = document.querySelector('.js-loading')
  loading.classList.add('is-loaded')
})

/**
 * DWHデータ更新ボタンが押された時の動き
 */
const confirmDwhUpdateBtn = document.querySelector('.js-confirmDwhUpdateButton')
confirmDwhUpdateBtn.addEventListener('click', function () {
    // 確認モーダルを開く
    document.querySelector('.js-confirmDwhUpdateModal').classList.add('is-modal-open')
});

/**
 * Datalakeデータ更新ボタンが押された時の動き
 */
const confirmDatalakeUpdateBtn = document.querySelector('.js-confirmDatalakeUpdateButton')
confirmDatalakeUpdateBtn.addEventListener('click', function () {
    // 確認モーダルを開く
    document.querySelector('.js-confirmDatalakeUpdateModal').classList.add('is-modal-open')
});

/**
 * モーダルクローズボタン
 */
document.querySelectorAll('.js-modalCloseBtn').forEach(function (btn) {
    btn.addEventListener('click', function () {
        document.querySelectorAll('.js-modal').forEach(function (modalElm) {
            modalElm.classList.remove('is-modal-open')
        })
    })
})
